// === ACTION TYPES ===

// GET /box
export const BOX_REQUEST = 'BOX_REQUEST'
export const BOX_REQUEST_SUCCESS = 'BOX_REQUEST_SUCCESS'
export const BOX_REQUEST_FAILURE = 'BOX_REQUEST_FAILURE'

// GET /status/${statusId}
export const STATUS_REQUEST = 'STATUS_REQUEST'
export const STATUS_REQUEST_SUCCESS = 'STATUS_REQUEST_SUCCESS'
export const STATUS_REQUEST_FAILURE = 'STATUS_REQUEST_FAILURE'

// GET /boxes
export const BOXES_REQUEST = 'BOXES_REQUEST'
export const BOXES_REQUEST_SUCCESS = 'BOXES_REQUEST_SUCCESS'
export const BOXES_REQUEST_FAILURE = 'BOXES_REQUEST_FAILURE'

// PUT /boxes/${boxid}/reboot
export const BOXES_REBOOT_REQUEST = 'BOXES_REBOOT_REQUEST'
export const BOXES_REBOOT_REQUEST_SUCCESS = 'BOXES_REBOOT_REQUEST_SUCCESS'
export const BOXES_REBOOT_REQUEST_FAILURE = 'BOXES_REBOOT_REQUEST_FAILURE'

// GET /boxes/${boxid}/streams
export const STREAMS_REQUEST = 'STREAMS_REQUEST'
export const STREAMS_REQUEST_FAILURE = 'STREAMS_REQUEST_FAILURE'
export const STREAMS_REQUEST_SUCCESS = 'STREAMS_REQUEST_SUCCESS'

// PUT /boxes/${boxid}/${streamid}
export const SAVE_STREAM_REQUEST = 'SAVE_STREAM_REQUEST'
export const SAVE_STREAM_REQUEST_FAILURE = 'SAVE_STREAM_REQUEST_FAILURE'
export const SAVE_STREAM_REQUEST_SUCCESS = 'SAVE_STREAM_REQUEST_SUCCESS'

// DELETE /boxes/${boxid}/${streamid}
export const DELETE_STREAM_REQUEST = 'DELETE_STREAM_REQUEST'
export const DELETE_STREAM_REQUEST_FAILURE = 'DELETE_STREAM_REQUEST_FAILURE'
export const DELETE_STREAM_REQUEST_SUCCESS = 'DELETE_STREAM_REQUEST_SUCCESS'

// GET /streamInfos
export const STREAMS_INFO_REQUEST = 'STREAMS_INFO_REQUEST'
export const STREAMS_INFO_REQUEST_FAILURE = 'STREAMS_INFO_REQUEST_FAILURE'
export const STREAMS_INFO_REQUEST_SUCCESS = 'STREAMS_INFO_REQUEST_SUCCESS'

export const STREAMS_DETAILS_REQUEST = 'STREAMS_DETAILS_REQUEST'
export const RESET_STREAM_DETAILS = 'RESET_STREAM_DETAILS'
export const STREAMS_DETAILS_REQUEST_FAILURE = 'STREAMS_DETAILS_REQUEST_FAILURE'
export const STREAMS_DETAILS_REQUEST_SUCCESS = 'STREAMS_DETAILS_REQUEST_SUCCESS'

export const SAVE_STREAMS_DETAILS_REQUEST = 'SAVE_STREAMS_DETAILS_REQUEST'
export const SAVE_STREAMS_DETAILS_REQUEST_SUCCESS =
  'SAVE_STREAMS_DETAILS_REQUEST_SUCCESS'
export const SAVE_STREAMS_DETAILS_REQUEST_FAILURE =
  'SAVE_STREAMS_DETAILS_REQUEST_FAILURE'

export const LOAD_ALL_STREAMS_DETAILS_REQUEST = 'SAVE_STREAMS_DETAILS_REQUEST'
export const LOAD_ALL_STREAMS_DETAILS_REQUEST_SUCCESS =
  'LOAD_ALL_STREAMS_DETAILS_REQUEST_SUCCESS'
export const LOAD_ALL_STREAMS_DETAILS_REQUEST_FAILURE =
  'LOAD_ALL_STREAMS_DETAILS_REQUEST_FAILURE'

// POST/PUT /boxes
export const SAVE_BOX_REQUEST = 'SAVE_BOX_REQUEST'
export const SAVE_BOX_REQUEST_SUCCESS = 'SAVE_BOX_REQUEST_SUCCESS'
export const SAVE_BOX_REQUEST_FAILURE = 'SAVE_BOX_REQUEST_FAILURE'

// GET /boxes/${boxid}/getFrame
export const GET_CAMERA_FRAME = 'GET_CAMERA_FRAME'
export const GET_CAMERA_FRAME_SUCCESS = 'GET_CAMERA_FRAME_SUCCESS'
export const GET_CAMERA_FRAME_FAILURE = 'GET_CAMERA_FRAME_FAILURE'
export const RESET_CAMERA_FRAME = 'RESET_CAMERA_FRAME'

// GET /boxes/${boxid}/${streamId}/configurations/camera
export const CAMERA_CONFIG_REQUEST = 'CAMERA_CONFIG_REQUEST'
export const CAMERA_CONFIG_REQUEST_SUCCESS = 'CAMERA_CONFIG_REQUEST_SUCCESS'
export const CAMERA_CONFIG_REQUEST_FAILURE = 'CAMERA_CONFIG_REQUEST_FAILURE'

// GET /boxes/${id}/eventTriggers
export const BOX_EVENT_TRIGGERS_REQUEST = 'BOX_EVENT_TRIGGERS_REQUEST'
export const BOX_EVENT_TRIGGERS_REQUEST_SUCCESS =
  'BOX_EVENT_TRIGGERS_REQUEST_SUCCESS'
export const BOX_EVENT_TRIGGERS_REQUEST_FAILURE =
  'BOX_EVENT_TRIGGERS_REQUEST_FAILURE'
export const RESET_BOX_EVENT_TRIGGERS = 'RESET_EVENT_TRIGGERS'

// PUT /boxes/${id}/eventTriggers
export const SAVE_BOX_EVENT_TRIGGER_REQUEST = 'SAVE_BOX_EVENT_TRIGGER_REQUEST'
export const SAVE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS =
  'SAVE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS'
export const SAVE_BOX_EVENT_TRIGGER_REQUEST_FAILURE =
  'SAVE_BOX_EVENT_TRIGGER_REQUEST_FAILURE'

export const SAVE_BOX_EVENT_TRIGGERS_REQUEST = 'SAVE_BOX_EVENT_TRIGGERS_REQUEST'
export const SAVE_BOX_EVENT_TRIGGERS_REQUEST_SUCCESS =
  'SAVE_BOX_EVENT_TRIGGERS_REQUEST_SUCCESS'
export const SAVE_BOX_EVENT_TRIGGERS_REQUEST_FAILURE =
  'SAVE_BOX_EVENT_TRIGGERS_REQUEST_FAILURE'

export const SAVE_BOX_EVENT_TRIGGERS_ROIINTERVAL_REQUEST =
  'SAVE_BOX_EVENT_TRIGGERS_ROIINTERVAL_REQUEST'
export const SAVE_BOX_EVENT_TRIGGERS_ROIINTERVAL_SUCCESS =
  'SAVE_BOX_EVENT_TRIGGERS_ROIINTERVAL_SUCCESS'
export const SAVE_BOX_EVENT_TRIGGERS_ROIINTERVAL_FAILURE =
  'SAVE_BOX_EVENT_TRIGGERS_ROIINTERVAL_FAILURE'

// BOX EVENT TRIGGERS
export const UPDATE_BOX_EVENT_TRIGGER = 'UPDATE_BOX_EVENT_TRIGGER'
export const SET_SELECTED_BOX_EVENT_TRIGGER = 'SET_SELECTED_BOX_EVENT_TRIGGER'
export const SET_HIGHLIGHTED_BOX_EVENT_TRIGGER =
  'SET_HIGHLIGHTED_BOX_EVENT_TRIGGER'
export const ADD_BOX_EVENT_TRIGGER = 'ADD_BOX_EVENT_TRIGGER'
export const DELETE_BOX_EVENT_TRIGGER = 'DELETE_BOX_EVENT_TRIGGER'

// DELETE /boxes/${id}/eventTriggers/${triggerId}
export const DELETE_BOX_EVENT_TRIGGER_REQUEST =
  'DELETE_BOX_EVENT_TRIGGER_REQUEST'
export const DELETE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS =
  'DELETE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS'
export const DELETE_BOX_EVENT_TRIGGER_REQUEST_FAILURE =
  'DELETE_BOX_EVENT_TRIGGERS_REQUEST_FAILURE'

// GET /boxes/${boxId}/${streamId}/eventTriggers/rules
export const BOX_EVENT_RULES_REQUEST = 'BOX_EVENT_RULES_REQUEST'
export const BOX_EVENT_RULES_REQUEST_SUCCESS = 'BOX_EVENT_RULES_REQUEST_SUCCESS'
export const BOX_EVENT_RULES_REQUEST_FAILURE = 'BOX_EVENT_RULES_REQUEST_FAILURE'

// GET /boxes/eventTriggers/rules/templates
export const BOX_EVENT_RULES_TEMPLATES_REQUEST =
  'BOX_EVENT_RULES_TEMPLATES_REQUEST'
export const BOX_EVENT_RULES_TEMPLATES_REQUEST_SUCCESS =
  'BOX_EVENT_RULES_TEMPLATES_REQUEST_SUCCESS'
export const BOX_EVENT_RULES_TEMPLATES_REQUEST_FAILURE =
  'BOX_EVENT_RULES_TEMPLATES_REQUEST_FAILURE'

// PUT /boxes/${boxId}/${streamId}/eventTriggers/rules/${$ruleId}
export const SAVE_BOX_EVENT_RULE_REQUEST = 'SAVE_BOX_EVENT_RULE_REQUEST'
export const SAVE_BOX_EVENT_RULE_REQUEST_SUCCESS =
  'SAVE_BOX_EVENT_RULE_REQUEST_SUCCESS'
export const SAVE_BOX_EVENT_RULE_REQUEST_FAILURE =
  'SAVE_BOX_EVENT_RULE_REQUEST_FAILURE'

export const ADD_LOCAL_BOX_EVENT_RULES_REQUEST =
  'ADD_LOCAL_BOX_EVENT_RULES_REQUEST'

export const SAVE_BOX_EVENT_RULES_REQUEST = 'SAVE_BOX_EVENT_RULES_REQUEST'
export const SAVE_BOX_EVENT_RULES_REQUEST_SUCCESS =
  'SAVE_BOX_EVENT_RULES_REQUEST_SUCCESS'
export const SAVE_BOX_EVENT_RULES_REQUEST_FAILURE =
  'SAVE_BOX_EVENT_RULES_REQUEST_FAILURE'

// DELETE /boxes/${boxId}/${streamId}/eventTriggers/rules/${ruleId}
export const DELETE_BOX_EVENT_RULES_REQUEST = 'DELETE_BOX_EVENT_RULES_REQUEST'
export const DELETE_BOX_EVENT_RULES_REQUEST_SUCCESS =
  'DELETE_BOX_EVENT_RULES_REQUEST_SUCCESS'
export const DELETE_BOX_EVENT_RULES_REQUEST_FAILURE =
  'DELETE_BOX_EVENT_RULES_REQUEST_FAILURE'

// GET /configurations/mqtt/${uuid}
export const MQTT_CONFIG_REQUEST = 'MQTT_CONFIG_REQUEST'
export const MQTT_CONFIG_REQUEST_SUCCESS = 'MQTT_CONFIG_REQUEST_SUCCESS'
export const MQTT_CONFIG_REQUEST_FAILURE = 'MQTT_CONFIG_REQUEST_FAILURE'

// GET /configurations/ioTrigger/${uuid}
export const IOTRIGGER_CONFIG_REQUEST = 'IOTRIGGER_CONFIG_REQUEST'
export const IOTRIGGER_CONFIG_REQUEST_SUCCESS =
  'IOTRIGGER_CONFIG_REQUEST_SUCCESS'
export const IOTRIGGER_CONFIG_REQUEST_FAILURE =
  'IOTRIGGER_CONFIG_REQUEST_FAILURE'

// POST/PUT /configurations/camera
export const SAVE_CAMERA_CONFIG_REQUEST = 'SAVE_CAMERA_CONFIG_REQUEST'
export const SAVE_CAMERA_CONFIG_REQUEST_SUCCESS =
  'SAVE_CAMERA_CONFIG_REQUEST_SUCCESS'
export const SAVE_CAMERA_CONFIG_REQUEST_FAILURE =
  'SAVE_CAMERA_CONFIG_REQUEST_FAILURE'

// POST/PUT /configurations/mqtt
export const SAVE_MQTT_CONFIG_REQUEST = 'SAVE_MQTT_CONFIG_REQUEST'
export const SAVE_MQTT_CONFIG_REQUEST_SUCCESS =
  'SAVE_MQTT_CONFIG_REQUEST_SUCCESS'
export const SAVE_MQTT_CONFIG_REQUEST_FAILURE =
  'SAVE_MQTT_CONFIG_REQUEST_FAILURE'

// POST/PUT /configurations/ioTrigger
export const SAVE_IOTRIGGER_CONFIG_REQUEST = 'SAVE_IOTRIGGER_CONFIG_REQUEST'
export const SAVE_IOTRIGGER_CONFIG_REQUEST_SUCCESS =
  'SAVE_IOTRIGGER_CONFIG_REQUEST_SUCCESS'
export const SAVE_IOTRIGGER_CONFIG_REQUEST_FAILURE =
  'SAVE_IOTRIGGER_CONFIG_REQUEST_FAILURE'

// DELETE /configurations/ioTrigger/${uuid}
export const DELETE_IOTRIGGER_CONFIG_REQUEST = 'DELETE_IOTRIGGER_CONFIG_REQUEST'
export const DELETE_IOTRIGGER_CONFIG_REQUEST_SUCCESS =
  'DELETE_IOTRIGGER_CONFIG_REQUEST_SUCCESS'
export const DELETE_IOTRIGGER_CONFIG_REQUEST_FAILURE =
  'DELETE_IOTRIGGER_CONFIG_REQUEST_FAILURE'

export const BOX_DETAILS_REQUEST = 'BOX_DETAILS_REQUEST'
export const BOX_DETAILS_REQUEST_SUCCESS = 'BOX_DETAILS_REQUEST_SUCCESS'
export const BOX_DETAILS_REQUEST_FAILURE = 'BOX_DETAILS_REQUEST_FAILURE'
export const RESET_BOX_DETAILS = 'RESET_BOX_DETAILS'

// All box configurations have been saved
export const SAVE_BOX_DETAILS_REQUEST = 'SAVE_BOX_DETAILS_REQUEST'
export const SAVE_BOX_DETAILS_REQUEST_SUCCESS =
  'SAVE_BOX_DETAILS_REQUEST_SUCCESS'
export const SAVE_BOX_DETAILS_REQUEST_FAILURE =
  'SAVE_BOX_DETAILS_REQUEST_FAILURE'

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE'
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE'

// PUT /solutions/groups/${groupId}
export const SAVE_GROUP_REQUEST = 'SAVE_GROUP_REQUEST'
export const SAVE_GROUP_REQUEST_FAILURE = 'SAVE_GROUP_REQUEST_FAILURE'
export const SAVE_GROUP_REQUEST_SUCCESS = 'SAVE_GROUP_REQUEST_SUCCESS'

// DELETE /solutions/groups/${groupId}
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST'
export const DELETE_GROUP_REQUEST_FAILURE = 'DELETE_GROUP_REQUEST_FAILURE'
export const DELETE_GROUP_REQUEST_SUCCESS = 'DELETE_GROUP_REQUEST_SUCCESS'

// GET /solutions/groups/${groupId}
export const LIST_GROUPS_REQUEST = 'LIST_GROUPS_REQUEST'
export const LIST_GROUPS_REQUEST_FAILURE = 'LIST_GROUPS_REQUEST_FAILURE'
export const LIST_GROUPS_REQUEST_SUCCESS = 'LIST_GROUPS_REQUEST_SUCCESS'

// PUT /solutions/scenes/${sceneId}
export const SAVE_SCENE_REQUEST = 'SAVE_SCENE_REQUEST'
export const SAVE_SCENE_REQUEST_FAILURE = 'SAVE_SCENE_REQUEST_FAILURE'
export const SAVE_SCENE_REQUEST_SUCCESS = 'SAVE_SCENE_REQUEST_SUCCESS'

// DELETE /solutions/scenes/${sceneId}
export const DELETE_SCENE_REQUEST = 'DELETE_SCENE_REQUEST'
export const DELETE_SCENE_REQUEST_FAILURE = 'DELETE_SCENE_REQUEST_FAILURE'
export const DELETE_SCENE_REQUEST_SUCCESS = 'DELETE_SCENE_REQUEST_SUCCESS'

// GET /solutions/scenes
export const LIST_SCENES_REQUEST = 'LIST_SCENES_REQUEST'
export const LIST_SCENES_REQUEST_FAILURE = 'LIST_SCENES_REQUEST_FAILURE'
export const LIST_SCENES_REQUEST_SUCCESS = 'LIST_SCENES_REQUEST_SUCCESS'

// GET /solutions/groups/${groupId}/scenes
export const LIST_SCENES_GROUP_REQUEST = 'LIST_SCENES_GROUP_REQUEST'
export const LIST_SCENES_GROUP_REQUEST_FAILURE =
  'LIST_SCENES_GROUP_REQUEST_FAILURE'
export const LIST_SCENES_GROUP_REQUEST_SUCCESS =
  'LIST_SCENES_GROUP_REQUEST_SUCCESS'

// GET /solutions/scenes/${sceneId}/crossinglines
export const LIST_SCENES_CLINES_REQUEST = 'LIST_SCENES_CLINES_REQUEST'
export const LIST_SCENES_CLINES_REQUEST_FAILURE =
  'LIST_SCENES_CLINES_REQUEST_FAILURE'
export const LIST_SCENES_CLINES_REQUEST_SUCCESS =
  'LIST_SCENES_CLINES_REQUEST_SUCCESS'

// GET /solutions/scenes/${sceneId}/speedlines
export const LIST_SCENES_SPEEDLINES_REQUEST = 'LIST_SCENES_SPEEDLINES_REQUEST'
export const LIST_SCENES_SPEEDLINES_REQUEST_FAILURE =
  'LIST_SCENES_SPEEDLINES_REQUEST_FAILURE'
export const LIST_SCENES_SPEEDLINES_REQUEST_SUCCESS =
  'LIST_SCENES_SPEEDLINES_REQUEST_SUCCESS'

// GET /solutions/scenes/${sceneId}/regions
export const LIST_SCENES_REGIONS_REQUEST = 'LIST_SCENES_REGIONS_REQUEST'
export const LIST_SCENES_REGIONS_REQUEST_FAILURE =
  'LIST_SCENES_REGIONS_REQUEST_FAILURE'
export const LIST_SCENES_REGIONS_REQUEST_SUCCESS =
  'LIST_SCENES_REGIONS_REQUEST_SUCCESS'

// GET /solutions/scenes/${sceneId}/rules
export const LIST_SCENES_RULES_REQUEST = 'LIST_SCENES_RULES_REQUEST'
export const LIST_SCENES_RULES_REQUEST_FAILURE =
  'LIST_SCENES_RULES_REQUEST_FAILURE'
export const LIST_SCENES_RULES_REQUEST_SUCCESS =
  'LIST_SCENES_RULES_REQUEST_SUCCESS'

// GET /solutions/scenes/anprimages
export const GET_ANPRIMAGE_REQUEST = 'GET_ANPRIMAGE_REQUEST'
export const GET_ANPRIMAGE_REQUEST_FAILURE = 'GET_ANPRIMAGE_REQUEST_FAILURE'
export const GET_ANPRIMAGE_REQUEST_SUCCESS = 'GET_ANPRIMAGE_REQUEST_SUCCESS'

// PUT /monitoring/alerts/${alertID}
export const SAVE_ALERT_REQUEST = 'SAVE_ALERT_REQUEST'
export const SAVE_ALERT_REQUEST_FAILURE = 'SAVE_ALERT_REQUEST_FAILURE'
export const SAVE_ALERT_REQUEST_SUCCESS = 'SAVE_ALERT_REQUEST_SUCCESS'

// DELETE /monitoring/alerts/${alertID}
export const DELETE_ALERT_REQUEST = 'DELETE_ALERT_REQUEST'
export const DELETE_ALERT_REQUEST_FAILURE = 'DELETE_ALERT_REQUEST_FAILURE'
export const DELETE_ALERT_REQUEST_SUCCESS = 'DELETE_ALERT_REQUEST_SUCCESS'

// GET /tenantAdministration/users
export const LIST_USERS_REQUEST = 'LIST_USERS_REQUEST'
export const LIST_USERS_REQUEST_FAILURE = 'LIST_USERS_REQUEST_FAILURE'
export const LIST_USERS_REQUEST_SUCCESS = 'LIST_USERS_REQUEST_SUCCESS'

// PUT /tenantAdministration/users/ID
export const SAVE_USER_REQUEST = 'SAVE_USER_REQUEST'
export const SAVE_USER_REQUEST_FAILURE = 'SAVE_USER_REQUEST_FAILURE'
export const SAVE_USER_REQUEST_SUCCESS = 'SAVE_USER_REQUEST_SUCCESS'

// POST /tenantAdministration/users
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_REQUEST_FAILURE = 'CREATE_USER_REQUEST_FAILURE'
export const CREATE_USER_REQUEST_SUCCESS = 'CREATE_USER_REQUEST_SUCCESS'

// DELETE /tenantAdministration/users/ID
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_REQUEST_FAILURE = 'DELETE_USER_REQUEST_FAILURE'
export const DELETE_USER_REQUEST_SUCCESS = 'DELETE_USER_REQUEST_SUCCESS'

// GET /monitoring/alerts
export const LIST_ALERTS_REQUEST = 'LIST_ALERTS_REQUEST'
export const LIST_ALERTS_REQUEST_FAILURE = 'LIST_ALERTS_REQUEST_FAILURE'
export const LIST_ALERTS_REQUEST_SUCCESS = 'LIST_ALERTS_REQUEST_SUCCESS'

// GET /licenses/list
export const LIST_LICENSES_REQUEST = 'LIST_LICENSES_REQUEST'
export const LIST_LICENSES_REQUEST_FAILURE = 'LIST_LICENSES_REQUEST_FAILURE'
export const LIST_LICENSES_REQUEST_SUCCESS = 'LIST_LICENSES_REQUEST_SUCCESS'

// GET /licenses
export const LICENSE_STATUS_REQUEST = 'LICENSE_STATUS_REQUEST'
export const LICENSE_STATUS_REQUEST_FAILURE = 'LICENSE_STATUS_REQUEST_FAILURE'
export const LICENSE_STATUS_REQUEST_SUCCESS = 'LICENSE_STATUS_REQUEST_SUCCESS'

// GET /boxes/${boxid}/${streamid}/onvif/recommendations
export const GET_ONVIF_RECOMMENDATIONS_REQUEST =
  'GET_ONVIF_RECOMMENDATIONS_REQUEST'
export const GET_ONVIF_RECOMMENDATIONS_REQUEST_FAILURE =
  'GET_ONVIF_RECOMMENDATIONS_REQUEST_FAILURE'
export const GET_ONVIF_RECOMMENDATIONS_REQUEST_SUCCESS =
  'GET_ONVIF_RECOMMENDATIONS_REQUEST_SUCCESS'

// PATCH /boxes/${boxid}/${streamid}/onvif/settings
export const PATCH_ONVIF_SETTINGS_REQUEST = 'PATCH_ONVIF_SETTINGS_REQUEST'
export const PATCH_ONVIF_SETTINGS_REQUEST_FAILURE =
  'PATCH_ONVIF_SETTINGS_FAILURE'
export const PATCH_ONVIF_SETTINGS_REQUEST_SUCCESS =
  'PATCH_ONVIF_SETTINGS_SUCCESS'

export const PATCH_ONVIF_ZOOM_REQUEST = 'PATCH_ONVIF_ZOOM_REQUEST'
export const PATCH_ONVIF_ZOOM_REQUEST_FAILURE =
  'PATCH_ONVIF_ZOOM_REQUEST_FAILURE'
export const PATCH_ONVIF_ZOOM_REQUEST_SUCCESS =
  'PATCH_ONVIF_ZOOM_REQUEST_SUCCESS'

// GET /tenantAdministration/devices/list
export const TENANT_BOXES_REQUEST = 'TENANT_BOXES_REQUEST'
export const TENANT_BOXES_REQUEST_SUCCESS = 'TENANT_BOXES_REQUEST_SUCCESS'
export const TENANT_BOXES_REQUEST_FAILURE = 'TENANT_BOXES_REQUEST_FAILURE'

// GET /tenantAdministration/tenants/list
export const TENANTS_LIST_REQUEST = 'TENANTS_LIST_REQUEST'
export const TENANTS_LIST_REQUEST_SUCCESS = 'TENANTS_LIST_REQUEST_SUCCESS'
export const TENANTS_LIST_REQUEST_FAILURE = 'TENANTS_LIST_REQUEST_FAILURE'

// POST /tenantAdministration/tenants/{tenantId}/devices
export const TENANTS_MOVE_REQUEST = 'TENANTS_MOVE_REQUEST'
export const TENANTS_MOVE_REQUEST_SUCCESS = 'TENANTS_MOVE_REQUEST_SUCCESS'
export const TENANTS_MOVE_REQUEST_FAILURE = 'TENANTS_MOVE_REQUEST_FAILURE'

export const NEWEST_EDGE_VERSION_TARGETED_REQUEST =
  'NEWEST_EDGE_VERSION_TARGETED_REQUEST'
export const NEWEST_EDGE_VERSION_TARGETED_REQUEST_SUCCESS =
  'NEWEST_EDGE_VERSION_TARGETED_REQUEST_SUCCESS'
export const NEWEST_EDGE_VERSION_TARGETED_REQUEST_FAILURE =
  'NEWEST_EDGE_VERSION_TARGETED_REQUEST_FAILURE'

export const EDGE_UPDATE_SCHEDULES_REQUEST = 'EDGE_UPDATE_SCHEDULES_REQUEST'
export const EDGE_UPDATE_SCHEDULES_REQUEST_SUCCESS =
  'EDGE_UPDATE_SCHEDULES_REQUEST_SUCCESS'
export const EDGE_UPDATE_SCHEDULES_REQUEST_FAILURE =
  'EDGE_UPDATE_SCHEDULES_REQUEST_FAILURE'

export const TARGET_NEWEST_EDGE_VERSION_REQUEST =
  'TARGET_NEWEST_EDGE_VERSION_REQUEST'
export const TARGET_NEWEST_EDGE_VERSION_REQUEST_SUCCESS =
  'TARGET_NEWEST_EDGE_VERSION_REQUEST_SUCCESS'
export const TARGET_NEWEST_EDGE_VERSION_REQUEST_FAILURE =
  'TARGET_NEWEST_EDGE_VERSION_REQUEST_FAILURE'

export const GET_BOX_ACTION_SCHEDULES_REQUEST =
  'GET_BOX_ACTION_SCHEDULES_REQUEST'
export const GET_BOX_ACTION_SCHEDULES_REQUEST_SUCCESS =
  'GET_BOX_ACTION_SCHEDULES_REQUEST_SUCCESS'
export const GET_BOX_ACTION_SCHEDULES_REQUEST_FAILURE =
  'GET_BOX_ACTION_SCHEDULES_REQUEST_FAILURE'

export const SAVE_BOX_ACTION_SCHEDULE_REQUEST =
  'SAVE_BOX_ACTION_SCHEDULE_REQUEST'
export const SAVE_BOX_ACTION_SCHEDULE_REQUEST_SUCCESS =
  'SAVE_BOX_ACTION_SCHEDULE_REQUEST_SUCCESS'
export const SAVE_BOX_ACTION_SCHEDULE_REQUEST_FAILURE =
  'SAVE_BOX_ACTION_SCHEDULE_REQUEST_FAILURE'
