import {
  GET_BOX_ACTION_SCHEDULES_REQUEST,
  GET_BOX_ACTION_SCHEDULES_REQUEST_FAILURE,
  GET_BOX_ACTION_SCHEDULES_REQUEST_SUCCESS,
  SAVE_BOX_ACTION_SCHEDULE_REQUEST,
  SAVE_BOX_ACTION_SCHEDULE_REQUEST_FAILURE,
  SAVE_BOX_ACTION_SCHEDULE_REQUEST_SUCCESS
} from './actionTypes'
import { client, getAuthorizationHeader } from '../middleware/api'

const fetchBoxActionScheduleBegin = () => ({
  type: GET_BOX_ACTION_SCHEDULES_REQUEST
})

const fetchBoxActionScheduleSuccess = (boxActionSchedule) => ({
  type: GET_BOX_ACTION_SCHEDULES_REQUEST_SUCCESS,
  payload: { boxActionSchedule }
})

const fetchBoxActionScheduleFailure = (error) => ({
  type: GET_BOX_ACTION_SCHEDULES_REQUEST_FAILURE,
  payload: { error }
})

export const callFetchBoxActionSchedule = (boxId: string) => {
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(fetchBoxActionScheduleBegin())
      client
        .get(`boxes/${boxId}/actionSchedule`, header)
        .then((response) => {
          dispatch(fetchBoxActionScheduleSuccess(response.data))
          return response.data
        })
        .catch((error) => {
          dispatch(fetchBoxActionScheduleFailure(error))
        })
    })
  }
}

const saveBoxActionScheduleBegin = () => ({
  type: SAVE_BOX_ACTION_SCHEDULE_REQUEST
})

const saveBoxActionScheduleSuccess = (boxActionSchedule) => ({
  type: SAVE_BOX_ACTION_SCHEDULE_REQUEST_SUCCESS,
  payload: { boxActionSchedule }
})

const saveBoxActionScheduleFailure = (error) => ({
  type: SAVE_BOX_ACTION_SCHEDULE_REQUEST_FAILURE,
  payload: { error }
})

export const callSaveBoxActionSchedule = (boxId: string, boxActionSchedule) => {
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(saveBoxActionScheduleBegin())
      client
        .put(`boxes/${boxId}/actionSchedule`, boxActionSchedule, header)
        .then((response) => {
          dispatch(saveBoxActionScheduleSuccess(response.data))
          return response.data
        })
        .catch((error) => {
          dispatch(saveBoxActionScheduleFailure(error))
        })
    })
  }
}
