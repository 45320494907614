import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Row,
  Space,
  Table,
  Tag
} from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  CloudDownloadOutlined,
  InfoCircleOutlined,
  SearchOutlined
} from '@ant-design/icons'
import './EdgeUpdate.scss'
import ConfirmationDialog from '../ConfirmDialog/ConfirmationDialog'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../../redux/store/configureStore'
import {
  loadIsNewestEdgeVersionTargeted,
  targetNewestEdgeVersion,
  loadEdgeUpdateSchedules
} from '../../redux/actions/tenantAdministration'
import { NameIdCell } from '../BoxList/cells'
import { clipLongText } from '../BoxDetailsHeader/MetadataTable'
import { IUpdateSchedule } from '../../types/updateSchedule'
import { IBox } from '../../types/box'
import moment from 'moment/moment'
const EdgeUpdatePage: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  // ----------------- redux states -----------------

  const { newestEdgeVersionTargeted } = useSelector((state: ReduxStore) => ({
    newestEdgeVersionTargeted: state.tenantAdministration.edgeVersionUpdated
  }))

  const { edgeUpdateSchedules, edgeUpdateSchedulesFetched } = useSelector(
    (state: ReduxStore) => ({
      edgeUpdateSchedules: state.tenantAdministration.edgeUpdateSchedules,
      edgeUpdateSchedulesFetched:
        state.tenantAdministration.edgeUpdateSchedulesFetched
    })
  )

  useMemo(() => {
    edgeUpdateSchedules.sort((s1, s2) => {
      if (s1.schedule && !s2.schedule) {
        return 1
      } else if (!s1.schedule && s2.schedule) {
        return -1
      } else if (!s1.box.name) {
        return 1
      } else if (!s2.box.name) {
        return -1
      } else {
        return s1.box.name.localeCompare(s2.box.name)
      }
    })
  }, [edgeUpdateSchedules])

  // ----------------- states -----------------

  const getDisabledHours = (date) => {
    let disabledHours: number[] = []
    for (let i = 0; i < 24; i++) {
      if (
        moment(date).hour(i).endOf('hour').utc() <
        moment().startOf('hour').utc()
      ) {
        disabledHours.push(i)
      }
    }
    return disabledHours
  }

  const [scheduleDateTime, setScheduleDateTime] = useState<moment.Moment>(
    moment().startOf('hour').utc()
  )

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [filteredRowKeys, setFilteredRowKeys] = useState<string[]>([])
  const [disabledHours, setDisabledHours] = useState<number[]>(
    getDisabledHours(scheduleDateTime)
  )
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  // ----------------- helpers -----------------
  const isInstantUpdate = () => scheduleDateTime < moment().utc()

  const isAllRowKeysSelected = useMemo(() => {
    return selectedRowKeys.length === edgeUpdateSchedules.length
  }, [selectedRowKeys, edgeUpdateSchedules])

  // ----------------- effects -----------------

  useEffect(() => {
    dispatch(loadIsNewestEdgeVersionTargeted())
    dispatch(loadEdgeUpdateSchedules())
  }, [dispatch])

  useEffect(() => {
    setSelectedRowKeys(
      edgeUpdateSchedules
        .filter((schedule) => !schedule.schedule)
        .map((schedule) => schedule.box.id)
    )
    setFilteredRowKeys(edgeUpdateSchedules.map((schedule) => schedule.box.id))
  }, [edgeUpdateSchedules])

  // ----------------- table content -----------------

  const data: any = useMemo(() => {
    let bulidData: any = []
    edgeUpdateSchedules.forEach((schedule: IUpdateSchedule) => {
      bulidData.push({
        key: schedule.box.id,
        name_id: (
          <NameIdCell
            box={schedule.box}
            key={schedule.box.name + schedule.box.id + schedule.box.serial}
          />
        ),
        metadata:
          schedule.box.metadata &&
          Object.entries(schedule.box.metadata).length > 0
            ? Object.entries(schedule.box.metadata)
                .sort((entry1, entry2) => entry1[0].localeCompare(entry2[0]))
                .map(([key, value]) => (
                  <Tag key={schedule.box.id + key}>
                    {clipLongText(key, 25)}:{' '}
                    {
                      // @ts-ignore
                      clipLongText(value, 25)
                    }
                  </Tag>
                ))
            : null,
        schedule: (
          <span className="scc--edgeUpdate-update-schedule-column">
            {schedule.schedule
              ? moment(schedule.schedule).utc().format('YYYY-MM-DD HH:mm [UTC]')
              : ''}
          </span>
        ),
        enabled:
          !schedule.schedule ||
          moment(schedule.schedule).utc() > scheduleDateTime
      })
    })
    return bulidData
  }, [edgeUpdateSchedules, scheduleDateTime])

  let searchInput: Input | null = null
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder={t(`boxList.dataTable.search.${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('boxList.dataTable.search.submit')}
          </Button>
          <Button onClick={() => clearFilters()} type="link" size="small">
            {t('boxList.dataTable.search.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined className={filtered ? 'scc--boxlist--searched' : ''} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100)
      }
    },
    render: (text) => text
  })

  const columns = [
    {
      title: t('boxList.dataTable.headers.name_id'),
      dataIndex: 'name_id',
      key: 'name_id',
      width: '40%',
      ...getColumnSearchProps('name_id'),
      onFilter: (value, record) =>
        record && record.name_id && record.name_id.key
          ? record.name_id.key
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : false
    },
    {
      title: t('boxList.dataTable.headers.metadata'),
      dataIndex: 'metadata',
      key: 'metadata',
      width: '25%',
      ...getColumnSearchProps('metadata'),
      onFilter: (value, record) => {
        let box: IBox = edgeUpdateSchedules.find(
          (schedule) => schedule.box.id === record.key
        ).box
        if (!box || !box.metadata) {
          return false
        }
        return Object.values(box.metadata).some((metadataValue) =>
          metadataValue.toString().toLowerCase().includes(value.toLowerCase())
        )
      }
    },
    {
      title: t('boxList.dataTable.headers.edgeUpdateSchedule'),
      dataIndex: 'schedule',
      key: 'schedule',
      width: '25%'
    }
  ]

  // ----------------- render -----------------

  return (
    <>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Row>
          <Col>
            <h3>{t('edgeUpdate.title')}</h3>
          </Col>
        </Row>
        <Alert
          message={
            newestEdgeVersionTargeted
              ? t('edgeUpdate.info')
              : t('edgeUpdate.warning')
          }
          type={newestEdgeVersionTargeted ? 'info' : 'warning'}
          showIcon
        />
        {!newestEdgeVersionTargeted && (
          <>
            <Row className={'scc--edgeUpdate-container'}>
              <Col span={10}>
                <Card>
                  <Row justify="center" align="middle">
                    <Col>
                      <span className="scc--edgeUpdate-update-schedule-picker-text">
                        Schedule Update for:
                      </span>
                    </Col>
                    <Col>
                      <DatePicker
                        size="large"
                        className={'scc--edgeUpdate-update-schedule-picker'}
                        allowClear={!isInstantUpdate()}
                        value={scheduleDateTime}
                        showNow={false}
                        format={
                          scheduleDateTime < moment().utc()
                            ? '[now]'
                            : 'YYYY-MM-DD HH:mm [UTC]'
                        }
                        onChange={(date) => {
                          let newDate = date
                            ? date.startOf('hour').utc()
                            : moment().startOf('hour').utc()
                          newDate.utc()
                          let enabledSelectedRowKeys = selectedRowKeys.filter(
                            (key) =>
                              edgeUpdateSchedules.some(
                                (row) =>
                                  row.box.id === key &&
                                  (!row.schedule ||
                                    moment(row.schedule).utc() > newDate)
                              )
                          )
                          setScheduleDateTime(newDate)
                          setSelectedRowKeys(enabledSelectedRowKeys)
                        }}
                        onSelect={(date) => {
                          setDisabledHours(getDisabledHours(date))
                        }}
                        onOpenChange={(open) => {
                          if (open) {
                            setDisabledHours(getDisabledHours(scheduleDateTime))
                          }
                        }}
                        showTime={{
                          format: 'HH',
                          disabledHours: () => disabledHours
                        }}
                        disabledDate={(current) =>
                          // today -> 7 days in future
                          current &&
                          (current < moment().utc().startOf('day') ||
                            current >
                              moment().utc().startOf('day').add(7, 'days'))
                        }
                      />
                    </Col>
                  </Row>
                  <Button
                    children={
                      <>
                        &nbsp;
                        <Trans
                          i18nKey={
                            isAllRowKeysSelected
                              ? t('edgeUpdate.button.all', {
                                  deviceAmount: selectedRowKeys.length
                                })
                              : t('edgeUpdate.button.selected', {
                                  deviceAmount: selectedRowKeys.length
                                })
                          }
                        />
                      </>
                    }
                    disabled={selectedRowKeys.length === 0}
                    icon={<CloudDownloadOutlined />}
                    style={{
                      width: '100%',
                      height: '80px',
                      fontSize: '20px',
                      marginTop: '20px'
                    }}
                    type={'primary'}
                    onClick={() => setModalVisible(true)}
                  />
                  <Alert
                    message={t('edgeUpdate.scheduleInfo')}
                    type={'info'}
                    showIcon={false}
                    className={'scc--edgeUpdate-schedule-alert'}
                  />
                </Card>
              </Col>
              <Col span={14}>
                <Card
                  title={
                    <>
                      {t('edgeUpdate.updateInfoCard.title')} &nbsp;
                      <InfoCircleOutlined />
                    </>
                  }
                  className={'scc--edgeUpdate--card'}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('edgeUpdate.updateInfoCard.content')
                    }}
                  />
                </Card>
              </Col>
            </Row>
            <Card
              title={t('edgeUpdate.deviceSelectionCard.title')}
              className="scc--edgeUpdate--card"
            >
              <Table
                rowClassName={(record) =>
                  record.enabled ? '' : 'scc--edgeupdate-tablerow--disabled'
                }
                columns={columns}
                onChange={(pagination, filters, sorter, extra) => {
                  if (extra.action === 'filter') {
                    setFilteredRowKeys(
                      extra.currentDataSource.map((row) => row.key)
                    )
                  }
                }}
                dataSource={data}
                pagination={{ defaultPageSize: 10 }}
                loading={!edgeUpdateSchedulesFetched}
                rowSelection={{
                  type: 'checkbox',
                  columnWidth: '3%',
                  preserveSelectedRowKeys: true,
                  selectedRowKeys: selectedRowKeys,
                  onSelect: (record, _selected, newSelectedRows) => {
                    record.enabled &&
                      setSelectedRowKeys(
                        newSelectedRows.map((row) => row.key) as [string]
                      )
                  },

                  columnTitle: (
                    <Checkbox
                      checked={isAllRowKeysSelected}
                      indeterminate={
                        selectedRowKeys.length > 0 && !isAllRowKeysSelected
                      }
                      onChange={(e) => {
                        let enabledFilteredRowKeys = filteredRowKeys.filter(
                          (key) => data.find((row) => row.key === key).enabled
                        )
                        e.target.checked &&
                        selectedRowKeys.length !== enabledFilteredRowKeys.length
                          ? setSelectedRowKeys(enabledFilteredRowKeys)
                          : setSelectedRowKeys([])
                      }}
                    />
                  )
                }}
              />
            </Card>
          </>
        )}
      </Space>
      <ConfirmationDialog
        primaryButtonText={t('edgeUpdate.confirmation.primaryButton')}
        secondaryButtonText={t('edgeUpdate.confirmation.secondaryButton')}
        modalHeading={
          <Trans
            i18nKey={t('edgeUpdate.confirmation.header', {
              deviceAmount: selectedRowKeys.length,
              selectedTimeInfo:
                scheduleDateTime < moment().utc()
                  ? null
                  : scheduleDateTime.format('[at] YYYY-MM-DD HH:mm [UTC]')
            })}
          />
        }
        danger={true}
        size="sm"
        open={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        onRequestSubmit={() => {
          setModalVisible(false)
          dispatch(
            targetNewestEdgeVersion(
              selectedRowKeys,
              scheduleDateTime.toISOString()
            )
          )
        }}
      >
        {t('edgeUpdate.confirmation.text')}
      </ConfirmationDialog>
    </>
  )
}

export default EdgeUpdatePage
