import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  MinusCircleTwoTone,
  QuestionCircleTwoTone,
  WarningTwoTone
} from '@ant-design/icons'
import { ERuntimeState } from '../../../types/runtimeState'
import { EErrorReason } from '../../../types/errorReason'
import { Tooltip } from 'antd'

const OperationalStatusCell = ({
  runtimeState,
  errorReason,
  styleOverride
}: {
  runtimeState: ERuntimeState
  errorReason?: EErrorReason
  styleOverride?: CSSProperties
}) => {
  const { t } = useTranslation()

  const errorReasonToCamelCase = (errorReason: string) => {
    let errorReasonCamelCase = ''
    const errorReasonArray = errorReason.split('_')
    for (let i = 0; i < errorReasonArray.length; i++) {
      if (i === 0) {
        errorReasonCamelCase = errorReasonArray[i].toLowerCase()
      } else {
        errorReasonCamelCase =
          errorReasonCamelCase +
          errorReasonArray[i].charAt(0).toUpperCase() +
          errorReasonArray[i].slice(1).toLowerCase()
      }
    }
    return errorReasonCamelCase
  }

  const getTooltipContent = () => {
    let errorReasons = ''

    if (errorReason && errorReason.length > 0) {
      Object.values(EErrorReason).forEach((reason) => {
        if (errorReason.indexOf(reason) > -1) {
          errorReasons =
            errorReasons +
            t(
              `boxList.dataTable.runtimeStatus.errorReasons.${errorReasonToCamelCase(
                reason
              )}`
            )
        }
      })
    }
    return errorReasons
  }

  const status = (runtimeState: ERuntimeState) => {
    switch (runtimeState) {
      case ERuntimeState.operational:
        return <CheckCircleTwoTone twoToneColor="#00a86b" />
      case ERuntimeState.notoperational:
        return <WarningTwoTone twoToneColor="#da1e28" />
      case ERuntimeState.warning:
        return <WarningTwoTone twoToneColor="orange" />
      case ERuntimeState.notconfigured:
      case ERuntimeState.disabled:
        return <MinusCircleTwoTone twoToneColor="#d3d3d3" />
      case ERuntimeState.pending:
      case ERuntimeState.standby:
        return <ClockCircleTwoTone twoToneColor="#d3d3d3" />
      case ERuntimeState.unknown:
      default:
        return <QuestionCircleTwoTone twoToneColor="orange" />
    }
  }

  return (
    <div
      style={
        styleOverride
          ? styleOverride
          : {
              marginLeft: 'auto',
              textAlign: 'center',
              width: '9em',
              display: 'flex',
              alignItems: 'center'
            }
      }
    >
      {status(runtimeState)}
      {(runtimeState === 'NOT_OPERATIONAL' || runtimeState === 'WARNING') &&
      errorReason &&
      errorReason.length > 0 ? (
        <Tooltip
          overlayStyle={{ whiteSpace: 'pre-line' }}
          title={getTooltipContent}
        >
          <div style={styleOverride ? {} : { paddingLeft: '0.5em' }}>
            {t(`boxList.dataTable.runtimeStatus.${runtimeState}`)}
          </div>
        </Tooltip>
      ) : (
        <div style={styleOverride ? {} : { paddingLeft: '0.5em' }}>
          {t(`boxList.dataTable.runtimeStatus.${runtimeState}`)}
        </div>
      )}
    </div>
  )
}

export default OperationalStatusCell
