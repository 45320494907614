import ConfirmationDialog from './ConfirmationDialog'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const DeleteConfirmationDialog = (props: {
  showAdditionalText?: boolean
  customText?: string
  additionalText?: string
  onRequestSubmit: any
  onSecondarySubmit: any
  onRequestClose: any
  open: any
}) => {
  const { t } = useTranslation()

  return (
    <ConfirmationDialog
      primaryButtonText={t('modal.delete.primaryButton.title')}
      secondaryButtonText={t('modal.delete.secondaryButton.title')}
      modalHeading={t('modal.delete.heading')}
      danger={true}
      size="xs"
      onRequestSubmit={props.onRequestSubmit}
      onSecondarySubmit={props.onSecondarySubmit}
      onRequestClose={props.onRequestClose}
      open={props.open}
    >
      {props.customText ?? t('modal.delete.text')}
      {props.showAdditionalText && props.additionalText && (
        <>
          <br />
          <br />
          <div className="scc--rule-engine--delete-trigger">
            {props.additionalText}
          </div>
        </>
      )}
    </ConfirmationDialog>
  )
}
