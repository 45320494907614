import axios, { AxiosRequestConfig } from 'axios'
import { normalize, schema } from 'normalizr'
import { Config } from '../../services/config'
import { log } from '../../services/log'
import {
  getTokenInformation,
  leaveHeaderEmptyOnMissingOrMainTenant
} from '../../services/apiTokenProvider'

const API_ROOT = Config.API_ROOT

const boxSchema = new schema.Entity('boxes')
const streamSchema = new schema.Entity('streams')
const configSchema = new schema.Entity('configurations')
const frameSchema = new schema.Entity('frame')
const statusSchema = new schema.Entity('status')
const eventTriggerSchema = new schema.Entity('eventTriggers')
const eventRuleSchema = new schema.Entity('eventRules')
const groupSchema = new schema.Entity('group')
const sceneSchema = new schema.Entity('scene')
const alertSchema = new schema.Entity('alerts')
const userSchema = new schema.Entity('users')
const onvifSchema = new schema.Entity('onvif')
const tenantSchema = new schema.Entity('tenant')
const updateScheduleSchema = new schema.Entity('updateSchedule')
const boxActionScheduleSchema = new schema.Entity('boxActionSchedule')

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH'
}

export const client = axios.create({
  baseURL: API_ROOT,
  method: HTTP_METHOD.GET
})

interface IApiRequestConfig {
  type?: HTTP_METHOD
  endpoint: string
  schema?: schema.Entity
  payload?: Object
  id?: string
  responseClass?: Function
}

export const getAuthorizationHeader = async () => {
  const tokenInformation = await getTokenInformation()
  return {
    headers: leaveHeaderEmptyOnMissingOrMainTenant(tokenInformation)
      ? {
          Authorization: `Bearer ${tokenInformation.idToken}`
        }
      : {
          Authorization: `Bearer ${tokenInformation.idToken}`,
          Tenant: tokenInformation.tenantInformations!.tenantId
        }
  }
}

/**
 * Fetches an API response and normalizes the result JSON according to schema.
 * This makes every API response have the same shape, regardless of how nested it was.
 * Necessary in development as prism returns random uuids.
 * @param requestConfig
 */
export const callApi = async (requestConfig: IApiRequestConfig) => {
  const { type, payload, id, responseClass, schema, endpoint } = requestConfig

  let config: AxiosRequestConfig = {
    method: type || HTTP_METHOD.GET,
    url: endpoint,
    ...(await getAuthorizationHeader())
  }

  if (payload) {
    config.data = payload
  }
  return client(config).then((response) => {
    let data = response.data

    if (!data) {
      return { config, response, id }
    }

    if (id) {
      data = Object.assign({}, data, { id })
    }

    // Convert the response to a class instance if provided
    if (responseClass) {
      return new (responseClass as any)(data)
    }

    if (schema && typeof data === 'object') {
      // Return normalized API response
      return Object.assign({}, normalize(data, schema))
    }

    return data
  })
}

export const Schemas = {
  BOX: boxSchema,
  BOX_ARRAY: [boxSchema],
  STREAM: streamSchema,
  STREAM_ARRAY: [streamSchema],
  CONFIG: configSchema,
  FRAME: frameSchema,
  STATUS: statusSchema,
  EVENT_TRIGGER: eventTriggerSchema,
  EVENT_RULES: eventRuleSchema,
  GROUP: groupSchema,
  SCENE: sceneSchema,
  ALERTS: alertSchema,
  USERS: userSchema,
  ONVIF: onvifSchema,
  TENANT_ARRAY: [tenantSchema],
  UPDATE_SCHEDULE_ARRAY: [updateScheduleSchema],
  BOX_ACTION_SCHEDULE: boxActionScheduleSchema
}

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API'

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
const fn = (store) => (next) => (action) => {
  const callAPI = action[CALL_API]

  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  let endpoint = callAPI.endpoint
  const { schema, types, id, method, payload, responseClass } = callAPI

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState())
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }

  if (!types.every((type) => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data)
    delete finalAction[CALL_API]
    return finalAction
  }

  const type = method || HTTP_METHOD.GET

  const [requestType, successType, failureType] = types

  next(actionWith({ type: requestType }))

  return callApi({
    type,
    endpoint,
    schema,
    payload,
    id,
    responseClass
  })
    .then((response) => {
      return next(
        actionWith({
          response,
          type: successType
        })
      )
    })
    .catch((error) => {
      next(
        actionWith({
          type: failureType,
          error: error || 'Something bad happened',
          id
        })
      )

      log.error(error)
      throw error
    })
}
export default fn
