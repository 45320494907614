export enum EMqttConfigurationType {
  default = 'default',
  custom = 'custom'
}

export enum EMqttQos {
  AtMostOnce,
  AtLeastOnce,
  ExactlyOnce
}

export interface IMqttConfiguration {
  boxId?: string
  streamId?: string
  objectType?: string
  configurationType?: EMqttConfigurationType
  username: string
  password: string
  compression: boolean
  host: string
  port?: number
  path?: string
  topic?: string
  attachLicensePlateImage: boolean
  clientId?: string
  qos?: number
}

export const DefaultMqttConfiguration: IMqttConfiguration = {
  objectType: 'MqttConfiguration',
  configurationType: EMqttConfigurationType.default,
  username: '',
  password: '',
  compression: false,
  host: '',
  port: undefined,
  path: undefined,
  topic: undefined,
  attachLicensePlateImage: false,
  clientId: undefined,
  qos: EMqttQos.AtLeastOnce.valueOf()
}

export enum EIoTriggerConfigurationType {
  quido = 'quido'
}

export enum EQuidoDeviceType {
  fourfour = 'quido_4/4',
  eighteight = 'quido_8/8',
  twosixteen = 'quido_2/16'
}

export interface IIoTriggerConfiguration {
  objectType?: string
  host?: string
  configurationType?: EIoTriggerConfigurationType
  quidoDeviceType: EQuidoDeviceType
}

export const DefaultIoTriggerConfiguration: IIoTriggerConfiguration = {
  objectType: 'IoTriggerConfiguration',
  host: '',
  configurationType: EIoTriggerConfigurationType.quido,
  quidoDeviceType: EQuidoDeviceType.fourfour
}
