import AntdConfig from 'react-awesome-query-builder/lib/config/antd'
import { Config, FieldOrGroup } from 'react-awesome-query-builder'
import {
  EEventTriggerType,
  ICoordinateBasedEventTrigger
} from '../../types/eventTrigger'
import { mapTriggersForQueryConfig } from './index'

// @ts-ignore
const InitialConfig: Config = {
  ...AntdConfig,
  // @ts-ignore
  settings: {
    ...AntdConfig.settings,
    valueSourcesPopupTitle: 'Select Mode',
    addRuleLabel: 'Add subcondition',
    fieldSeparatorDisplay: '-',
    canRegroup: false,
    canReorder: false
  },
  // @ts-ignore
  operators: {
    ...AntdConfig.operators,
    // @ts-ignore
    select_equals: {
      ...AntdConfig.operators.select_equals,
      label: 'Equals',
      labelForFormat: 'EQUALS',
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    select_not_equals: {
      ...AntdConfig.operators.select_not_equals,
      label: 'Not equals',
      labelForFormat: 'NOT EQUALS',
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    equal: {
      ...AntdConfig.operators.equal,
      label: 'Equals',
      labelForFormat: 'EQUALS',
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    not_equal: {
      ...AntdConfig.operators.not_equal,
      label: 'Not equals',
      labelForFormat: 'NOT EQUALS',
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    }
  },
  // @ts-ignore
  types: {
    ...AntdConfig.types,
    // @ts-ignore
    text: {
      ...AntdConfig.types.text,
      excludeOperators: ['proximity', 'is_null', 'is_not_null']
    },
    // @ts-ignore
    number: {
      ...AntdConfig.types.number,
      widgets: {
        ...AntdConfig.types.number.widgets,
        slider: {
          operators: ['less', 'less_or_equal', 'greater', 'greater_or_equal']
        }
      },
      defaultOperator: 'greater',
      excludeOperators: [
        'equal',
        'not_equal',
        'proximity',
        'is_null',
        'is_not_null',
        'is_empty',
        'is_not_empty'
      ]
    },
    // @ts-ignore
    select: {
      ...AntdConfig.types.select,
      excludeOperators: ['is_null', 'is_not_null'],
      widgets: {
        ...AntdConfig.types.select.widgets,
        field: {
          operators: ['select_equals', 'select_not_equals']
        }
      }
    },
    '!group': {
      defaultOperator: 'greater_or_equal',
      mainWidget: 'number',
      widgets: {
        number: {
          widgetProps: {
            min: 0,
            max: 100
          },
          operators: [
            'less',
            'greater',
            'less_or_equal',
            'greater_or_equal',
            'between'
          ],
          opProps: {
            // @ts-ignore
            less: {
              label: 'Count <'
            },
            // @ts-ignore
            less_or_equal: {
              label: 'Count <='
            },
            // @ts-ignore
            greater: {
              label: 'Count >'
            },
            // @ts-ignore
            greater_or_equal: {
              label: 'Count >='
            },
            // @ts-ignore
            between: {
              label: 'Count between'
            }
          }
        }
      }
    }
  }
}

const classes: FieldOrGroup = {
  label: 'Class',
  type: 'select',
  valueSources: ['value'],
  operators: [
    'select_equals',
    'select_not_equals',
    'select_any_in',
    'select_not_any_in'
  ],
  fieldSettings: {
    listValues: [
      { value: 'car', title: 'Car' },
      { value: 'truck', title: 'Truck' },
      { value: 'bus', title: 'Bus' },
      { value: 'motorbike', title: 'Motorbike' },
      { value: 'bicycle', title: 'Bicycle' },
      { value: 'person', title: 'Person' },
      { value: 'scooter', title: 'Scooter' },
      { value: 'tram', title: 'Tram' },
      { value: 'other', title: 'Other' }
    ]
  }
}

const subclasses: FieldOrGroup = {
  label: 'Subclass',
  type: 'select',
  valueSources: ['value'],
  operators: [
    'select_equals',
    'select_not_equals',
    'select_any_in',
    'select_not_any_in'
  ],
  fieldSettings: {
    listValues: [
      { value: 'van', title: 'Van' },
      { value: 'car-with-trailer', title: 'Car with Trailer' },
      { value: 'single-unit-truck', title: 'Single unit Truck' },
      { value: 'truck-with-trailer', title: 'Truck with Trailer' },
      { value: 'articulated-truck', title: 'Articulated Truck' }
    ]
  }
}

const directions: FieldOrGroup = {
  label: 'Direction',
  type: 'select',
  valueSources: ['value'],
  operators: ['select_equals', 'select_not_equals'],
  fieldSettings: {
    listValues: [
      { value: 'in', title: 'In' },
      { value: 'out', title: 'Out' }
    ]
  }
}

export const vdConfig: Config = {
  ...InitialConfig,
  // @ts-ignore
  fields: {
    vd: {
      label: 'Virtual Door',
      tooltip: 'Group of fields',
      type: '!struct',
      operators: ['equal'],
      subfields: {
        class: classes,
        subclass: subclasses,
        direction: directions
      }
    }
  }
}

export const odConfig: (zones: triggerMapping[]) => Config = (
  odZoneMappings
) => {
  return {
    ...InitialConfig,
    //ts-ignore
    fields: {
      od: {
        label: 'Origin Destination',
        tooltip: 'Group of fields',
        type: '!struct',
        operators: ['equal'],
        subfields: {
          class: { ...classes, hideForCompare: true },
          subclass: { ...subclasses, hideForCompare: true },
          entryZoneId: {
            label: 'Origin',
            type: 'select',
            operators: [
              'select_equals',
              'select_not_equals',
              'select_any_in',
              'select_not_any_in'
            ],
            valueSources: ['value', 'field'],
            fieldSettings: {
              listValues: odZoneMappings
            }
          },
          exitZoneId: {
            label: 'Destination',
            type: 'select',
            operators: [
              'select_equals',
              'select_not_equals',
              'select_any_in',
              'select_not_any_in'
            ],
            valueSources: ['value', 'field'],
            fieldSettings: {
              listValues: odZoneMappings
            }
          }
        }
      }
    }
  }
}

export const clConfig: Config = {
  ...InitialConfig,
  // @ts-ignore
  operators: {
    // @ts-ignore
    ...InitialConfig.operators,
    starts_with: {
      ...InitialConfig.operators.starts_with,
      jsonLogic: 'startswith',
      labelForFormat: 'STARTS WITH',
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    ends_with: {
      ...InitialConfig.operators.ends_with,
      jsonLogic: 'endswith',
      labelForFormat: 'ENDS WITH',
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    }
  },
  fields: {
    cl: {
      label: 'Counting Line',
      tooltip: 'Group of fields',
      type: '!struct',
      operators: ['equal'],
      subfields: {
        class: classes,
        subclass: subclasses,
        direction: directions,
        speedEstimate: {
          label: 'Speed Estimation (km/h)',
          type: 'number',
          operators: [
            'between',
            'not_between',
            'less',
            'less_or_equal',
            'greater',
            'greater_or_equal'
          ],
          valueSources: ['value'],
          fieldSettings: {
            min: 0,
            max: 200
          },
          preferWidgets: ['slider', 'number']
        },
        licensePlate: {
          type: 'text',
          label: 'License Plate',
          operators: [
            'like',
            'not_like',
            'starts_with',
            'ends_with',
            'is_empty',
            'is_not_empty',
            'contains',
            'not_contains'
          ],
          fieldSettings: {
            validateValue: (val: string, fieldSettings) => {
              return (
                val.length < 10 &&
                (val === '' || val.match(/^[A-Za-z0-9öÖäÄüÜ]{1,11}$/) !== null)
              )
            }
          }
        }
      }
    }
  }
}

export const roiConfig: Config = {
  ...InitialConfig,
  // @ts-ignore
  fields: {
    roi: {
      label: 'Region of interest',
      tooltip: 'Group of fields',
      type: '!struct',
      operators: ['equal'],
      subfields: {
        state: {
          label: 'Occupancy State',
          type: 'select',
          valueSources: ['value'],
          operators: ['select_equals', 'select_not_equals'],
          fieldSettings: {
            listValues: [
              { value: 'free', title: 'Free' },
              { value: 'occupied', title: 'Occupied' }
            ]
          }
        },
        objects: {
          label: 'Object',
          tooltip: 'Group of fields',
          type: '!group',
          mode: 'array',
          defaultValue: 1,
          subfields: {
            class: classes,
            subclass: subclasses,
            dwellTime: {
              label: 'Dwell Time (s)',
              type: 'number',
              operators: [
                'between',
                'not_between',
                'less',
                'less_or_equal',
                'greater',
                'greater_or_equal'
              ],
              valueSources: ['value'],
              fieldSettings: {
                min: 0,
                max: 600
              },
              preferWidgets: ['slider', 'number']
            }
          }
        }
      }
    }
  }
}

export const getConfig = (
  eventTrigger: string | undefined,
  eventTriggerType: EEventTriggerType | undefined,
  allTriggers: ICoordinateBasedEventTrigger[]
): Config => {
  if (!eventTrigger) {
    return getGlobalConfig(eventTriggerType, allTriggers)
  }
  switch (eventTriggerType) {
    case EEventTriggerType.crossingLine:
      return clConfig
    case EEventTriggerType.virtualDoor:
      return vdConfig
    case EEventTriggerType.regionOfInterest:
      return roiConfig
  }
  console.error(
    'RuleEngine: Failed to load default config for ' + eventTriggerType + 'rule'
  )
  throw Error(
    'RuleEngine: Failed to load default config for ' + eventTrigger + 'rule'
  )
}

const getGlobalConfig = (
  eventTriggerType: EEventTriggerType | undefined,
  allTriggers: ICoordinateBasedEventTrigger[]
): Config => {
  //switch will get relevant when further globalTrigger are available
  if (eventTriggerType === EEventTriggerType.originDestinationZone) {
    return odConfig(mapTriggersForQueryConfig(allTriggers, eventTriggerType))
  }
  console.error(
    'RuleEngine: Failed to load default config for global ' +
      eventTriggerType +
      'rule'
  )
  throw Error(
    'RuleEngine: Failed to load default config for global ' +
      eventTriggerType +
      'rule'
  )
}

export interface triggerMapping {
  value: string
  title: string
}
