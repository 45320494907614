import {
  GET_BOX_ACTION_SCHEDULES_REQUEST,
  GET_BOX_ACTION_SCHEDULES_REQUEST_FAILURE,
  GET_BOX_ACTION_SCHEDULES_REQUEST_SUCCESS,
  SAVE_BOX_ACTION_SCHEDULE_REQUEST,
  SAVE_BOX_ACTION_SCHEDULE_REQUEST_FAILURE,
  SAVE_BOX_ACTION_SCHEDULE_REQUEST_SUCCESS
} from '../actions/actionTypes'
import { IBoxActionSchedule } from '../../types/boxActionSchedule'

const initialState = {
  boxActionSchedule: undefined as IBoxActionSchedule | undefined,
  loading: false,
  error: null
}

const boxActionScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOX_ACTION_SCHEDULES_REQUEST:
    case SAVE_BOX_ACTION_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case GET_BOX_ACTION_SCHEDULES_REQUEST_SUCCESS:
    case SAVE_BOX_ACTION_SCHEDULE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        boxActionSchedule: action.payload.boxActionSchedule
      }
    case GET_BOX_ACTION_SCHEDULES_REQUEST_FAILURE:
    case SAVE_BOX_ACTION_SCHEDULE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        boxActionSchedule: undefined
      }
    default:
      return state
  }
}

export default boxActionScheduleReducer
