import { ICoordinateBasedEventTrigger } from '../types/eventTrigger'
import { NavLink, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import {
  deleteStreamEventTrigger,
  resetBoxEventTriggers,
  setHighlightedStreamEventTrigger,
  setSelectedStreamEventTrigger
} from '../redux/actions/eventTriggers'
import { loadBoxDetails, resetBoxDetails } from '../redux/actions/boxDetails'
import { loadBoxStreams } from '../redux/actions/streams'
import { Tabs } from 'antd'
import BoxDetailsHeader from '../components/BoxDetailsHeader'
import { CloseOutline24 } from '@carbon/icons-react'
import { IBox } from '../types/box'
import { IBoxDetails } from '../types/boxDetails'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import StreamConfigurationContainer from './StreamConfigurationContainer'
import { IStream } from '../types/stream'
import { EDeviceView } from '../types/EDeviceView'
import DeviceHealth from '../components/DeviceHealth'
import cubejs, { CubejsApi, CubeJSApiOptions } from '@cubejs-client/core'
import {
  getTokenInformation,
  leaveHeaderEmptyOnMissingOrMainTenant
} from '../services/apiTokenProvider'
import { getApiUrl } from '../solutions/components/SceneOverview'
import './BoxDetailPage.scss'
import BoxScheduleCalendar from '../components/BoxScheduleCalendar'

interface IBoxDetailPageProps {
  children?
  match: any
  history: any
  box: IBox
  eventTriggers: ICoordinateBasedEventTrigger[]
  boxDetails: IBoxDetails
  streams: IStream[]
  updateBoxEventTrigger: any
  addBoxEventTrigger: (EEventTriggerType) => void
  deleteStreamEventTrigger: Function
  setSelectedBoxEventTrigger: (IEventTrigger) => void
  setHighlightedBoxEventTrigger: (IEventTrigger) => void
  highlightedEventTrigger: string
  selectedEventTrigger: string
  resetBoxDetails: Function
  loadBoxDetails: (string) => void
  loadBoxStreams: (string) => void
  resetBoxEventTriggers: Function
  t: any
  i18n: any
  tReady: any
}

interface IBoxDetailPageState {
  isDeleteConfirmationDialogVisible: boolean
  eventTriggerToDelete: ICoordinateBasedEventTrigger | null
  deviceView: EDeviceView
  cubejsApi: CubejsApi | null
}

class BoxDetailPage extends Component<
  IBoxDetailPageProps,
  IBoxDetailPageState
> {
  constructor(props) {
    super(props)

    this.state = {
      isDeleteConfirmationDialogVisible: false,
      eventTriggerToDelete: null,
      deviceView: EDeviceView.configuration,
      cubejsApi: null
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    // Prevent loading same data twice
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadData()
    }
  }

  loadData() {
    const { id } = this.props.match.params

    this.props.resetBoxDetails()
    this.props.resetBoxEventTriggers()

    if (id) {
      this.props.loadBoxDetails(id)
      this.props.loadBoxStreams(id)
    }
    if (!this.state.cubejsApi) {
      getTokenInformation().then((tokenInformation) => {
        const multiTenantHeaders = leaveHeaderEmptyOnMissingOrMainTenant(
          tokenInformation
        )
          ? undefined
          : {
              Tenant: tokenInformation.tenantInformations!.tenantId
            }
        const options: CubeJSApiOptions = !multiTenantHeaders
          ? {
              apiUrl: getApiUrl()
            }
          : {
              apiUrl: getApiUrl(),
              headers: multiTenantHeaders
            }
        const cubejsApi = cubejs(async () => {
          return (await getTokenInformation()).idToken
        }, options)
        this.setState({ cubejsApi: cubejsApi })
      })
    }
  }

  render() {
    const containerClassNames = this.props.box
      ? 'scc--boxdetail--container shown'
      : 'scc--boxdetail--container'

    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    })

    const changeTabPane = (key) => {
      this.setState({
        deviceView: EDeviceView[key]
      })
    }

    const { box, streams } = this.props

    streams.sort((a, b) => {
      if (a.name === undefined) {
        return 1
      }
      if (b.name === undefined) {
        return -1
      }
      return collator.compare(a.name, b.name)
    })

    return (
      <div className={containerClassNames}>
        <div onClick={() => changeTabPane(EDeviceView.configuration)}>
          <NavLink to="/boxes" className="scc--close-button">
            <CloseOutline24 className="scc--fill-main" />
          </NavLink>
        </div>
        <div className="scc--deviceconfig-container">
          <div className="bx--grid">
            <div className="bx--row">
              <div className="bx--col-sm-16 bx--col-md-16 bx--col-lg-16 scc--device-config--triggers scc--flex--column">
                {this.props.box && (
                  /*
                some explanation about the following part:
                to keep the style of the SCC wanted to use the Tabs component to switch between the configuration and health view.
                the stream configuration uses react-custom-scrollbars which have issues with height-calculation inside the tab-content.
                instead use tabs to select but render streamConfigContainer outside the tabs.
                As device health is pretty heavy workload due to all the graphics we only load it when we switch to the health view.
                We keep it loaded as long as detailPage is open and just make it invisible when switching back to configuration view.
                We switch back to configuration view when closing the devicedetailpage because opening animation gets laggy when opening in health view.
                */ <>
                    <BoxDetailsHeader
                      box={box}
                      tabPaneChanger={changeTabPane}
                    />
                    <Tabs
                      activeKey={this.state.deviceView}
                      type="card"
                      onChange={changeTabPane}
                    >
                      <Tabs.TabPane
                        tab={this.props.t(
                          'configuration.viewMode.configuration'
                        )}
                        key={EDeviceView.configuration}
                      />
                      <Tabs.TabPane
                        tab={this.props.t('configuration.viewMode.health')}
                        key={EDeviceView.health}
                      />
                      <Tabs.TabPane
                        tab={this.props.t('configuration.viewMode.schedule')}
                        key={EDeviceView.schedule}
                      />
                    </Tabs>
                    <div
                      className={
                        this.state.deviceView === EDeviceView.configuration
                          ? 'scc--boxdetail-component--visible'
                          : 'scc--boxdetail-component--hidden'
                      }
                    >
                      <StreamConfigurationContainer
                        box={box}
                        streams={streams}
                      />
                    </div>
                    {this.state.cubejsApi &&
                      this.state.deviceView === EDeviceView.health && (
                        <div className={'scc--boxdetail-component--visible'}>
                          <DeviceHealth
                            box={box}
                            streams={streams}
                            cubejsApi={this.state.cubejsApi}
                            key={'deviceHealth' + box.id}
                          />
                        </div>
                      )}
                    {this.state.deviceView === EDeviceView.schedule && (
                      <div className={'scc--boxdetail-component--visible'}>
                        <BoxScheduleCalendar boxId={box.id} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    box: state.boxes.byIds[ownProps.match.params.id],
    boxDetails: state.boxDetails,
    streams: state.streams.allIds.map((id) => state.streams.byIds[id]),
    selectedEventTrigger: state.eventTriggers.selectedEventTrigger,
    highlightedEventTrigger: state.eventTriggers.highlightedEventTrigger
  }
}

export default withRouter(
  connect(mapStateToProps, {
    loadBoxDetails,
    loadBoxStreams,
    deleteStreamEventTrigger,
    resetBoxDetails,
    resetBoxEventTriggers,
    setSelectedBoxEventTrigger: setSelectedStreamEventTrigger,
    setHighlightedBoxEventTrigger: setHighlightedStreamEventTrigger
  })(withTranslation()(BoxDetailPage))
)
